import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/sermons/importer/sermonsImporterSelectors';
import SermonsService from 'src/modules/sermons/sermonsService';
import fields from 'src/modules/sermons/importer/sermonsImporterFields';
import { i18n } from 'src/i18n';

const sermonsImporterActions = importerActions(
  'SERMONS_IMPORTER',
  selectors,
  SermonsService.import,
  fields,
  i18n('entities.sermons.importer.fileName'),
);

export default sermonsImporterActions;