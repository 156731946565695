import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/congregant/importer/congregantImporterSelectors';
import CongregantService from 'src/modules/congregant/congregantService';
import fields from 'src/modules/congregant/importer/congregantImporterFields';
import { i18n } from 'src/i18n';

const congregantImporterActions = importerActions(
  'CONGREGANT_IMPORTER',
  selectors,
  CongregantService.import,
  fields,
  i18n('entities.congregant.importer.fileName'),
);

export default congregantImporterActions;