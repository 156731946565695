import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'groupName',
    label: i18n('entities.groups.fields.groupName'),
    schema: schemas.string(
      i18n('entities.groups.fields.groupName'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'groupDescription',
    label: i18n('entities.groups.fields.groupDescription'),
    schema: schemas.string(
      i18n('entities.groups.fields.groupDescription'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'groupCongregationMembers',
    label: i18n('entities.groups.fields.groupCongregationMembers'),
    schema: schemas.relationToMany(
      i18n('entities.groups.fields.groupCongregationMembers'),
      {},
    ),
  },
  {
    name: 'groupProjects',
    label: i18n('entities.groups.fields.groupProjects'),
    schema: schemas.relationToMany(
      i18n('entities.groups.fields.groupProjects'),
      {},
    ),
  },
  {
    name: 'groupContributions',
    label: i18n('entities.groups.fields.groupContributions'),
    schema: schemas.relationToMany(
      i18n('entities.groups.fields.groupContributions'),
      {},
    ),
  },
  {
    name: 'groupEvents',
    label: i18n('entities.groups.fields.groupEvents'),
    schema: schemas.relationToMany(
      i18n('entities.groups.fields.groupEvents'),
      {},
    ),
  },
  {
    name: 'groupDocuments',
    label: i18n('entities.groups.fields.groupDocuments'),
    schema: schemas.relationToMany(
      i18n('entities.groups.fields.groupDocuments'),
      {},
    ),
  },
  {
    name: 'groupExpenses',
    label: i18n('entities.groups.fields.groupExpenses'),
    schema: schemas.relationToMany(
      i18n('entities.groups.fields.groupExpenses'),
      {},
    ),
  },
  {
    name: 'groupMessagesSent',
    label: i18n('entities.groups.fields.groupMessagesSent'),
    schema: schemas.relationToMany(
      i18n('entities.groups.fields.groupMessagesSent'),
      {},
    ),
  },
  {
    name: 'groupLeadServices',
    label: i18n('entities.groups.fields.groupLeadServices'),
    schema: schemas.relationToMany(
      i18n('entities.groups.fields.groupLeadServices'),
      {},
    ),
  },
];