import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.sermons.fields.id'),
  },
  {
    name: 'sermonTitle',
    label: i18n('entities.sermons.fields.sermonTitle'),
  },
  {
    name: 'sermonOfService',
    label: i18n('entities.sermons.fields.sermonOfService'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'sermonReadings',
    label: i18n('entities.sermons.fields.sermonReadings'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'sermonDocuments',
    label: i18n('entities.sermons.fields.sermonDocuments'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.sermons.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.sermons.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
