import list from 'src/modules/cars/list/carsListReducers';
import form from 'src/modules/cars/form/carsFormReducers';
import view from 'src/modules/cars/view/carsViewReducers';
import destroy from 'src/modules/cars/destroy/carsDestroyReducers';
import importerReducer from 'src/modules/cars/importer/carsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
