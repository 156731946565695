const messagesEnumerators = {
  messageType: [
    'SMS',
    'Whatsapp',
    'Email',
    'Call',
  ],
};

export default messagesEnumerators;
