import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.parkingSessions.fields.id'),
  },
  {
    name: 'parkingSessionName',
    label: i18n('entities.parkingSessions.fields.parkingSessionName'),
  },
  {
    name: 'parkingSessionStartTime',
    label: i18n('entities.parkingSessions.fields.parkingSessionStartTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'parkingSessionEndTime',
    label: i18n('entities.parkingSessions.fields.parkingSessionEndTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'parkingSessionParkings',
    label: i18n('entities.parkingSessions.fields.parkingSessionParkings'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.parkingSessions.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.parkingSessions.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
