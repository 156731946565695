import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'expenseName',
    label: i18n('entities.expenses.fields.expenseName'),
    schema: schemas.string(
      i18n('entities.expenses.fields.expenseName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'expenseDescription',
    label: i18n('entities.expenses.fields.expenseDescription'),
    schema: schemas.string(
      i18n('entities.expenses.fields.expenseDescription'),
      {},
    ),
  },
  {
    name: 'expenseOfProject',
    label: i18n('entities.expenses.fields.expenseOfProject'),
    schema: schemas.relationToOne(
      i18n('entities.expenses.fields.expenseOfProject'),
      {},
    ),
  },
  {
    name: 'expenseOfAsset',
    label: i18n('entities.expenses.fields.expenseOfAsset'),
    schema: schemas.relationToOne(
      i18n('entities.expenses.fields.expenseOfAsset'),
      {},
    ),
  },
  {
    name: 'expenseOfEvent',
    label: i18n('entities.expenses.fields.expenseOfEvent'),
    schema: schemas.relationToOne(
      i18n('entities.expenses.fields.expenseOfEvent'),
      {},
    ),
  },
  {
    name: 'expenseOfGroup',
    label: i18n('entities.expenses.fields.expenseOfGroup'),
    schema: schemas.relationToOne(
      i18n('entities.expenses.fields.expenseOfGroup'),
      {},
    ),
  },
];