import list from 'src/modules/parkings/list/parkingsListReducers';
import form from 'src/modules/parkings/form/parkingsFormReducers';
import view from 'src/modules/parkings/view/parkingsViewReducers';
import destroy from 'src/modules/parkings/destroy/parkingsDestroyReducers';
import importerReducer from 'src/modules/parkings/importer/parkingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
