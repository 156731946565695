import list from 'src/modules/services/list/servicesListReducers';
import form from 'src/modules/services/form/servicesFormReducers';
import view from 'src/modules/services/view/servicesViewReducers';
import destroy from 'src/modules/services/destroy/servicesDestroyReducers';
import importerReducer from 'src/modules/services/importer/servicesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
