import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/announcements/importer/announcementsImporterSelectors';
import AnnouncementsService from 'src/modules/announcements/announcementsService';
import fields from 'src/modules/announcements/importer/announcementsImporterFields';
import { i18n } from 'src/i18n';

const announcementsImporterActions = importerActions(
  'ANNOUNCEMENTS_IMPORTER',
  selectors,
  AnnouncementsService.import,
  fields,
  i18n('entities.announcements.importer.fileName'),
);

export default announcementsImporterActions;