import list from 'src/modules/membershipType/list/membershipTypeListReducers';
import form from 'src/modules/membershipType/form/membershipTypeFormReducers';
import view from 'src/modules/membershipType/view/membershipTypeViewReducers';
import destroy from 'src/modules/membershipType/destroy/membershipTypeDestroyReducers';
import importerReducer from 'src/modules/membershipType/importer/membershipTypeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
