import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/relationships/importer/relationshipsImporterSelectors';
import RelationshipsService from 'src/modules/relationships/relationshipsService';
import fields from 'src/modules/relationships/importer/relationshipsImporterFields';
import { i18n } from 'src/i18n';

const relationshipsImporterActions = importerActions(
  'RELATIONSHIPS_IMPORTER',
  selectors,
  RelationshipsService.import,
  fields,
  i18n('entities.relationships.importer.fileName'),
);

export default relationshipsImporterActions;