import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import readingsEnumerators from 'src/modules/readings/readingsEnumerators';

export default [
  {
    name: 'readingTitle',
    label: i18n('entities.readings.fields.readingTitle'),
    schema: schemas.string(
      i18n('entities.readings.fields.readingTitle'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'readingBook',
    label: i18n('entities.readings.fields.readingBook'),
    schema: schemas.enumerator(
      i18n('entities.readings.fields.readingBook'),
      {
        "options": readingsEnumerators.readingBook
      },
    ),
  },
  {
    name: 'readingChapter',
    label: i18n('entities.readings.fields.readingChapter'),
    schema: schemas.integer(
      i18n('entities.readings.fields.readingChapter'),
      {
        "max": 150,
        "required": true
      },
    ),
  },
  {
    name: 'readingStartVerse',
    label: i18n('entities.readings.fields.readingStartVerse'),
    schema: schemas.integer(
      i18n('entities.readings.fields.readingStartVerse'),
      {
        "max": 176,
        "required": true
      },
    ),
  },
  {
    name: 'readingEndVerse',
    label: i18n('entities.readings.fields.readingEndVerse'),
    schema: schemas.integer(
      i18n('entities.readings.fields.readingEndVerse'),
      {
        "max": 176
      },
    ),
  },
  {
    name: 'readingSermons',
    label: i18n('entities.readings.fields.readingSermons'),
    schema: schemas.relationToMany(
      i18n('entities.readings.fields.readingSermons'),
      {},
    ),
  },
];