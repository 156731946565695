import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/expenses/importer/expensesImporterSelectors';
import ExpensesService from 'src/modules/expenses/expensesService';
import fields from 'src/modules/expenses/importer/expensesImporterFields';
import { i18n } from 'src/i18n';

const expensesImporterActions = importerActions(
  'EXPENSES_IMPORTER',
  selectors,
  ExpensesService.import,
  fields,
  i18n('entities.expenses.importer.fileName'),
);

export default expensesImporterActions;