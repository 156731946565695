import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.congregant.fields.id'),
  },
  {
    name: 'congregantSurname',
    label: i18n('entities.congregant.fields.congregantSurname'),
  },
  {
    name: 'congregantFirstName',
    label: i18n('entities.congregant.fields.congregantFirstName'),
  },
  {
    name: 'congregantMiddleName',
    label: i18n('entities.congregant.fields.congregantMiddleName'),
  },
  {
    name: 'congregantAlias',
    label: i18n('entities.congregant.fields.congregantAlias'),
  },
  {
    name: 'congregantPhoneNumber',
    label: i18n('entities.congregant.fields.congregantPhoneNumber'),
  },
  {
    name: 'congregantEmail',
    label: i18n('entities.congregant.fields.congregantEmail'),
  },
  {
    name: 'congregantBirthdate',
    label: i18n('entities.congregant.fields.congregantBirthdate'),
  },
  {
    name: 'congregantGender',
    label: i18n('entities.congregant.fields.congregantGender'),
  },
  {
    name: 'congregantMembershipType',
    label: i18n('entities.congregant.fields.congregantMembershipType'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'congregantAreaOfResidence',
    label: i18n('entities.congregant.fields.congregantAreaOfResidence'),
  },
  {
    name: 'congregantContributions',
    label: i18n('entities.congregant.fields.congregantContributions'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'congregantGroup',
    label: i18n('entities.congregant.fields.congregantGroup'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'congregantRelationships',
    label: i18n('entities.congregant.fields.congregantRelationships'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'congregantEventsAttended',
    label: i18n('entities.congregant.fields.congregantEventsAttended'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'congregantDocument',
    label: i18n('entities.congregant.fields.congregantDocument'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'congregantMessagesSent',
    label: i18n('entities.congregant.fields.congregantMessagesSent'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'congregantParkings',
    label: i18n('entities.congregant.fields.congregantParkings'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'congregantCars',
    label: i18n('entities.congregant.fields.congregantCars'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.congregant.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.congregant.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
