import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.expenses.fields.id'),
  },
  {
    name: 'expenseName',
    label: i18n('entities.expenses.fields.expenseName'),
  },
  {
    name: 'expenseDescription',
    label: i18n('entities.expenses.fields.expenseDescription'),
  },
  {
    name: 'expenseOfProject',
    label: i18n('entities.expenses.fields.expenseOfProject'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'expenseOfAsset',
    label: i18n('entities.expenses.fields.expenseOfAsset'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'expenseOfEvent',
    label: i18n('entities.expenses.fields.expenseOfEvent'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'expenseOfGroup',
    label: i18n('entities.expenses.fields.expenseOfGroup'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.expenses.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.expenses.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
