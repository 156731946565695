import list from 'src/modules/groups/list/groupsListReducers';
import form from 'src/modules/groups/form/groupsFormReducers';
import view from 'src/modules/groups/view/groupsViewReducers';
import destroy from 'src/modules/groups/destroy/groupsDestroyReducers';
import importerReducer from 'src/modules/groups/importer/groupsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
