import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/assets/importer/assetsImporterSelectors';
import AssetsService from 'src/modules/assets/assetsService';
import fields from 'src/modules/assets/importer/assetsImporterFields';
import { i18n } from 'src/i18n';

const assetsImporterActions = importerActions(
  'ASSETS_IMPORTER',
  selectors,
  AssetsService.import,
  fields,
  i18n('entities.assets.importer.fileName'),
);

export default assetsImporterActions;