import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'documentName',
    label: i18n('entities.documents.fields.documentName'),
    schema: schemas.string(
      i18n('entities.documents.fields.documentName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'documentType',
    label: i18n('entities.documents.fields.documentType'),
    schema: schemas.relationToOne(
      i18n('entities.documents.fields.documentType'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'documentUpload',
    label: i18n('entities.documents.fields.documentUpload'),
    schema: schemas.files(
      i18n('entities.documents.fields.documentUpload'),
      {
        "required": true,
        "max": 5
      },
    ),
  },
  {
    name: 'documentOfCongregant',
    label: i18n('entities.documents.fields.documentOfCongregant'),
    schema: schemas.relationToOne(
      i18n('entities.documents.fields.documentOfCongregant'),
      {},
    ),
  },
  {
    name: 'documentOfGroup',
    label: i18n('entities.documents.fields.documentOfGroup'),
    schema: schemas.relationToOne(
      i18n('entities.documents.fields.documentOfGroup'),
      {},
    ),
  },
  {
    name: 'documentOfProject',
    label: i18n('entities.documents.fields.documentOfProject'),
    schema: schemas.relationToOne(
      i18n('entities.documents.fields.documentOfProject'),
      {},
    ),
  },
  {
    name: 'documentOfEvent',
    label: i18n('entities.documents.fields.documentOfEvent'),
    schema: schemas.relationToOne(
      i18n('entities.documents.fields.documentOfEvent'),
      {},
    ),
  },
  {
    name: 'documentOfSermon',
    label: i18n('entities.documents.fields.documentOfSermon'),
    schema: schemas.relationToOne(
      i18n('entities.documents.fields.documentOfSermon'),
      {},
    ),
  },
];