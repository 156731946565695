import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/events/importer/eventsImporterSelectors';
import EventsService from 'src/modules/events/eventsService';
import fields from 'src/modules/events/importer/eventsImporterFields';
import { i18n } from 'src/i18n';

const eventsImporterActions = importerActions(
  'EVENTS_IMPORTER',
  selectors,
  EventsService.import,
  fields,
  i18n('entities.events.importer.fileName'),
);

export default eventsImporterActions;