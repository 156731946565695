import Permissions from 'src/security/permissions';
import config from 'src/config';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/calendar',
    loader: () => import('src/view/calendar/CalendarPage'),
    permissionRequired: permissions.calendarRead,
  },
  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },
  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/congregant',
    loader: () =>
      import('src/view/congregant/list/CongregantListPage'),
    permissionRequired: permissions.congregantRead,
    exact: true,
  },
  {
    path: '/congregant/new',
    loader: () =>
      import('src/view/congregant/form/CongregantFormPage'),
    permissionRequired: permissions.congregantCreate,
    exact: true,
  },
  {
    path: '/congregant/importer',
    loader: () =>
      import(
        'src/view/congregant/importer/CongregantImporterPage'
      ),
    permissionRequired: permissions.congregantImport,
    exact: true,
  },
  {
    path: '/congregant/:id/edit',
    loader: () =>
      import('src/view/congregant/form/CongregantFormPage'),
    permissionRequired: permissions.congregantEdit,
    exact: true,
  },
  {
    path: '/congregant/:id',
    loader: () =>
      import('src/view/congregant/view/CongregantViewPage'),
    permissionRequired: permissions.congregantRead,
    exact: true,
  },

  {
    path: '/membership-type',
    loader: () =>
      import('src/view/membershipType/list/MembershipTypeListPage'),
    permissionRequired: permissions.membershipTypeRead,
    exact: true,
  },
  {
    path: '/membership-type/new',
    loader: () =>
      import('src/view/membershipType/form/MembershipTypeFormPage'),
    permissionRequired: permissions.membershipTypeCreate,
    exact: true,
  },
  {
    path: '/membership-type/importer',
    loader: () =>
      import(
        'src/view/membershipType/importer/MembershipTypeImporterPage'
      ),
    permissionRequired: permissions.membershipTypeImport,
    exact: true,
  },
  {
    path: '/membership-type/:id/edit',
    loader: () =>
      import('src/view/membershipType/form/MembershipTypeFormPage'),
    permissionRequired: permissions.membershipTypeEdit,
    exact: true,
  },
  {
    path: '/membership-type/:id',
    loader: () =>
      import('src/view/membershipType/view/MembershipTypeViewPage'),
    permissionRequired: permissions.membershipTypeRead,
    exact: true,
  },

  {
    path: '/groups',
    loader: () =>
      import('src/view/groups/list/GroupsListPage'),
    permissionRequired: permissions.groupsRead,
    exact: true,
  },
  {
    path: '/groups/new',
    loader: () =>
      import('src/view/groups/form/GroupsFormPage'),
    permissionRequired: permissions.groupsCreate,
    exact: true,
  },
  {
    path: '/groups/importer',
    loader: () =>
      import(
        'src/view/groups/importer/GroupsImporterPage'
      ),
    permissionRequired: permissions.groupsImport,
    exact: true,
  },
  {
    path: '/groups/:id/edit',
    loader: () =>
      import('src/view/groups/form/GroupsFormPage'),
    permissionRequired: permissions.groupsEdit,
    exact: true,
  },
  {
    path: '/groups/:id',
    loader: () =>
      import('src/view/groups/view/GroupsViewPage'),
    permissionRequired: permissions.groupsRead,
    exact: true,
  },

  {
    path: '/projects',
    loader: () =>
      import('src/view/projects/list/ProjectsListPage'),
    permissionRequired: permissions.projectsRead,
    exact: true,
  },
  {
    path: '/projects/new',
    loader: () =>
      import('src/view/projects/form/ProjectsFormPage'),
    permissionRequired: permissions.projectsCreate,
    exact: true,
  },
  {
    path: '/projects/importer',
    loader: () =>
      import(
        'src/view/projects/importer/ProjectsImporterPage'
      ),
    permissionRequired: permissions.projectsImport,
    exact: true,
  },
  {
    path: '/projects/:id/edit',
    loader: () =>
      import('src/view/projects/form/ProjectsFormPage'),
    permissionRequired: permissions.projectsEdit,
    exact: true,
  },
  {
    path: '/projects/:id',
    loader: () =>
      import('src/view/projects/view/ProjectsViewPage'),
    permissionRequired: permissions.projectsRead,
    exact: true,
  },

  {
    path: '/contributions',
    loader: () =>
      import('src/view/contributions/list/ContributionsListPage'),
    permissionRequired: permissions.contributionsRead,
    exact: true,
  },
  {
    path: '/contributions/new',
    loader: () =>
      import('src/view/contributions/form/ContributionsFormPage'),
    permissionRequired: permissions.contributionsCreate,
    exact: true,
  },
  {
    path: '/contributions/importer',
    loader: () =>
      import(
        'src/view/contributions/importer/ContributionsImporterPage'
      ),
    permissionRequired: permissions.contributionsImport,
    exact: true,
  },
  {
    path: '/contributions/:id/edit',
    loader: () =>
      import('src/view/contributions/form/ContributionsFormPage'),
    permissionRequired: permissions.contributionsEdit,
    exact: true,
  },
  {
    path: '/contributions/:id',
    loader: () =>
      import('src/view/contributions/view/ContributionsViewPage'),
    permissionRequired: permissions.contributionsRead,
    exact: true,
  },

  {
    path: '/contribution-types',
    loader: () =>
      import('src/view/contributionTypes/list/ContributionTypesListPage'),
    permissionRequired: permissions.contributionTypesRead,
    exact: true,
  },
  {
    path: '/contribution-types/new',
    loader: () =>
      import('src/view/contributionTypes/form/ContributionTypesFormPage'),
    permissionRequired: permissions.contributionTypesCreate,
    exact: true,
  },
  {
    path: '/contribution-types/importer',
    loader: () =>
      import(
        'src/view/contributionTypes/importer/ContributionTypesImporterPage'
      ),
    permissionRequired: permissions.contributionTypesImport,
    exact: true,
  },
  {
    path: '/contribution-types/:id/edit',
    loader: () =>
      import('src/view/contributionTypes/form/ContributionTypesFormPage'),
    permissionRequired: permissions.contributionTypesEdit,
    exact: true,
  },
  {
    path: '/contribution-types/:id',
    loader: () =>
      import('src/view/contributionTypes/view/ContributionTypesViewPage'),
    permissionRequired: permissions.contributionTypesRead,
    exact: true,
  },

  {
    path: '/events',
    loader: () =>
      import('src/view/events/list/EventsListPage'),
    permissionRequired: permissions.eventsRead,
    exact: true,
  },
  {
    path: '/events/new',
    loader: () =>
      import('src/view/events/form/EventsFormPage'),
    permissionRequired: permissions.eventsCreate,
    exact: true,
  },
  {
    path: '/events/importer',
    loader: () =>
      import(
        'src/view/events/importer/EventsImporterPage'
      ),
    permissionRequired: permissions.eventsImport,
    exact: true,
  },
  {
    path: '/events/:id/edit',
    loader: () =>
      import('src/view/events/form/EventsFormPage'),
    permissionRequired: permissions.eventsEdit,
    exact: true,
  },
  {
    path: '/events/:id',
    loader: () =>
      import('src/view/events/view/EventsViewPage'),
    permissionRequired: permissions.eventsRead,
    exact: true,
  },

  {
    path: '/documents',
    loader: () =>
      import('src/view/documents/list/DocumentsListPage'),
    permissionRequired: permissions.documentsRead,
    exact: true,
  },
  {
    path: '/documents/new',
    loader: () =>
      import('src/view/documents/form/DocumentsFormPage'),
    permissionRequired: permissions.documentsCreate,
    exact: true,
  },
  {
    path: '/documents/importer',
    loader: () =>
      import(
        'src/view/documents/importer/DocumentsImporterPage'
      ),
    permissionRequired: permissions.documentsImport,
    exact: true,
  },
  {
    path: '/documents/:id/edit',
    loader: () =>
      import('src/view/documents/form/DocumentsFormPage'),
    permissionRequired: permissions.documentsEdit,
    exact: true,
  },
  {
    path: '/documents/:id',
    loader: () =>
      import('src/view/documents/view/DocumentsViewPage'),
    permissionRequired: permissions.documentsRead,
    exact: true,
  },

  {
    path: '/document-types',
    loader: () =>
      import('src/view/documentTypes/list/DocumentTypesListPage'),
    permissionRequired: permissions.documentTypesRead,
    exact: true,
  },
  {
    path: '/document-types/new',
    loader: () =>
      import('src/view/documentTypes/form/DocumentTypesFormPage'),
    permissionRequired: permissions.documentTypesCreate,
    exact: true,
  },
  {
    path: '/document-types/importer',
    loader: () =>
      import(
        'src/view/documentTypes/importer/DocumentTypesImporterPage'
      ),
    permissionRequired: permissions.documentTypesImport,
    exact: true,
  },
  {
    path: '/document-types/:id/edit',
    loader: () =>
      import('src/view/documentTypes/form/DocumentTypesFormPage'),
    permissionRequired: permissions.documentTypesEdit,
    exact: true,
  },
  {
    path: '/document-types/:id',
    loader: () =>
      import('src/view/documentTypes/view/DocumentTypesViewPage'),
    permissionRequired: permissions.documentTypesRead,
    exact: true,
  },

  {
    path: '/assets',
    loader: () =>
      import('src/view/assets/list/AssetsListPage'),
    permissionRequired: permissions.assetsRead,
    exact: true,
  },
  {
    path: '/assets/new',
    loader: () =>
      import('src/view/assets/form/AssetsFormPage'),
    permissionRequired: permissions.assetsCreate,
    exact: true,
  },
  {
    path: '/assets/importer',
    loader: () =>
      import(
        'src/view/assets/importer/AssetsImporterPage'
      ),
    permissionRequired: permissions.assetsImport,
    exact: true,
  },
  {
    path: '/assets/:id/edit',
    loader: () =>
      import('src/view/assets/form/AssetsFormPage'),
    permissionRequired: permissions.assetsEdit,
    exact: true,
  },
  {
    path: '/assets/:id',
    loader: () =>
      import('src/view/assets/view/AssetsViewPage'),
    permissionRequired: permissions.assetsRead,
    exact: true,
  },

  {
    path: '/asset-increments',
    loader: () =>
      import('src/view/assetIncrements/list/AssetIncrementsListPage'),
    permissionRequired: permissions.assetIncrementsRead,
    exact: true,
  },
  {
    path: '/asset-increments/new',
    loader: () =>
      import('src/view/assetIncrements/form/AssetIncrementsFormPage'),
    permissionRequired: permissions.assetIncrementsCreate,
    exact: true,
  },
  {
    path: '/asset-increments/importer',
    loader: () =>
      import(
        'src/view/assetIncrements/importer/AssetIncrementsImporterPage'
      ),
    permissionRequired: permissions.assetIncrementsImport,
    exact: true,
  },
  {
    path: '/asset-increments/:id/edit',
    loader: () =>
      import('src/view/assetIncrements/form/AssetIncrementsFormPage'),
    permissionRequired: permissions.assetIncrementsEdit,
    exact: true,
  },
  {
    path: '/asset-increments/:id',
    loader: () =>
      import('src/view/assetIncrements/view/AssetIncrementsViewPage'),
    permissionRequired: permissions.assetIncrementsRead,
    exact: true,
  },

  {
    path: '/asset-decrements',
    loader: () =>
      import('src/view/assetDecrements/list/AssetDecrementsListPage'),
    permissionRequired: permissions.assetDecrementsRead,
    exact: true,
  },
  {
    path: '/asset-decrements/new',
    loader: () =>
      import('src/view/assetDecrements/form/AssetDecrementsFormPage'),
    permissionRequired: permissions.assetDecrementsCreate,
    exact: true,
  },
  {
    path: '/asset-decrements/importer',
    loader: () =>
      import(
        'src/view/assetDecrements/importer/AssetDecrementsImporterPage'
      ),
    permissionRequired: permissions.assetDecrementsImport,
    exact: true,
  },
  {
    path: '/asset-decrements/:id/edit',
    loader: () =>
      import('src/view/assetDecrements/form/AssetDecrementsFormPage'),
    permissionRequired: permissions.assetDecrementsEdit,
    exact: true,
  },
  {
    path: '/asset-decrements/:id',
    loader: () =>
      import('src/view/assetDecrements/view/AssetDecrementsViewPage'),
    permissionRequired: permissions.assetDecrementsRead,
    exact: true,
  },

  {
    path: '/expenses',
    loader: () =>
      import('src/view/expenses/list/ExpensesListPage'),
    permissionRequired: permissions.expensesRead,
    exact: true,
  },
  {
    path: '/expenses/new',
    loader: () =>
      import('src/view/expenses/form/ExpensesFormPage'),
    permissionRequired: permissions.expensesCreate,
    exact: true,
  },
  {
    path: '/expenses/importer',
    loader: () =>
      import(
        'src/view/expenses/importer/ExpensesImporterPage'
      ),
    permissionRequired: permissions.expensesImport,
    exact: true,
  },
  {
    path: '/expenses/:id/edit',
    loader: () =>
      import('src/view/expenses/form/ExpensesFormPage'),
    permissionRequired: permissions.expensesEdit,
    exact: true,
  },
  {
    path: '/expenses/:id',
    loader: () =>
      import('src/view/expenses/view/ExpensesViewPage'),
    permissionRequired: permissions.expensesRead,
    exact: true,
  },

  {
    path: '/messages',
    loader: () =>
      import('src/view/messages/list/MessagesListPage'),
    permissionRequired: permissions.messagesRead,
    exact: true,
  },
  {
    path: '/messages/new',
    loader: () =>
      import('src/view/messages/form/MessagesFormPage'),
    permissionRequired: permissions.messagesCreate,
    exact: true,
  },
  {
    path: '/messages/importer',
    loader: () =>
      import(
        'src/view/messages/importer/MessagesImporterPage'
      ),
    permissionRequired: permissions.messagesImport,
    exact: true,
  },
  {
    path: '/messages/:id/edit',
    loader: () =>
      import('src/view/messages/form/MessagesFormPage'),
    permissionRequired: permissions.messagesEdit,
    exact: true,
  },
  {
    path: '/messages/:id',
    loader: () =>
      import('src/view/messages/view/MessagesViewPage'),
    permissionRequired: permissions.messagesRead,
    exact: true,
  },

  {
    path: '/parkings',
    loader: () =>
      import('src/view/parkings/list/ParkingsListPage'),
    permissionRequired: permissions.parkingsRead,
    exact: true,
  },
  {
    path: '/parkings/new',
    loader: () =>
      import('src/view/parkings/form/ParkingsFormPage'),
    permissionRequired: permissions.parkingsCreate,
    exact: true,
  },
  {
    path: '/parkings/importer',
    loader: () =>
      import(
        'src/view/parkings/importer/ParkingsImporterPage'
      ),
    permissionRequired: permissions.parkingsImport,
    exact: true,
  },
  {
    path: '/parkings/:id/edit',
    loader: () =>
      import('src/view/parkings/form/ParkingsFormPage'),
    permissionRequired: permissions.parkingsEdit,
    exact: true,
  },
  {
    path: '/parkings/:id',
    loader: () =>
      import('src/view/parkings/view/ParkingsViewPage'),
    permissionRequired: permissions.parkingsRead,
    exact: true,
  },

  {
    path: '/parking-sessions',
    loader: () =>
      import('src/view/parkingSessions/list/ParkingSessionsListPage'),
    permissionRequired: permissions.parkingSessionsRead,
    exact: true,
  },
  {
    path: '/parking-sessions/new',
    loader: () =>
      import('src/view/parkingSessions/form/ParkingSessionsFormPage'),
    permissionRequired: permissions.parkingSessionsCreate,
    exact: true,
  },
  {
    path: '/parking-sessions/importer',
    loader: () =>
      import(
        'src/view/parkingSessions/importer/ParkingSessionsImporterPage'
      ),
    permissionRequired: permissions.parkingSessionsImport,
    exact: true,
  },
  {
    path: '/parking-sessions/:id/edit',
    loader: () =>
      import('src/view/parkingSessions/form/ParkingSessionsFormPage'),
    permissionRequired: permissions.parkingSessionsEdit,
    exact: true,
  },
  {
    path: '/parking-sessions/:id',
    loader: () =>
      import('src/view/parkingSessions/view/ParkingSessionsViewPage'),
    permissionRequired: permissions.parkingSessionsRead,
    exact: true,
  },

  {
    path: '/cars',
    loader: () =>
      import('src/view/cars/list/CarsListPage'),
    permissionRequired: permissions.carsRead,
    exact: true,
  },
  {
    path: '/cars/new',
    loader: () =>
      import('src/view/cars/form/CarsFormPage'),
    permissionRequired: permissions.carsCreate,
    exact: true,
  },
  {
    path: '/cars/importer',
    loader: () =>
      import(
        'src/view/cars/importer/CarsImporterPage'
      ),
    permissionRequired: permissions.carsImport,
    exact: true,
  },
  {
    path: '/cars/:id/edit',
    loader: () =>
      import('src/view/cars/form/CarsFormPage'),
    permissionRequired: permissions.carsEdit,
    exact: true,
  },
  {
    path: '/cars/:id',
    loader: () =>
      import('src/view/cars/view/CarsViewPage'),
    permissionRequired: permissions.carsRead,
    exact: true,
  },

  {
    path: '/relationships',
    loader: () =>
      import('src/view/relationships/list/RelationshipsListPage'),
    permissionRequired: permissions.relationshipsRead,
    exact: true,
  },
  {
    path: '/relationships/new',
    loader: () =>
      import('src/view/relationships/form/RelationshipsFormPage'),
    permissionRequired: permissions.relationshipsCreate,
    exact: true,
  },
  {
    path: '/relationships/importer',
    loader: () =>
      import(
        'src/view/relationships/importer/RelationshipsImporterPage'
      ),
    permissionRequired: permissions.relationshipsImport,
    exact: true,
  },
  {
    path: '/relationships/:id/edit',
    loader: () =>
      import('src/view/relationships/form/RelationshipsFormPage'),
    permissionRequired: permissions.relationshipsEdit,
    exact: true,
  },
  {
    path: '/relationships/:id',
    loader: () =>
      import('src/view/relationships/view/RelationshipsViewPage'),
    permissionRequired: permissions.relationshipsRead,
    exact: true,
  },

  {
    path: '/relationship-types',
    loader: () =>
      import('src/view/relationshipTypes/list/RelationshipTypesListPage'),
    permissionRequired: permissions.relationshipTypesRead,
    exact: true,
  },
  {
    path: '/relationship-types/new',
    loader: () =>
      import('src/view/relationshipTypes/form/RelationshipTypesFormPage'),
    permissionRequired: permissions.relationshipTypesCreate,
    exact: true,
  },
  {
    path: '/relationship-types/importer',
    loader: () =>
      import(
        'src/view/relationshipTypes/importer/RelationshipTypesImporterPage'
      ),
    permissionRequired: permissions.relationshipTypesImport,
    exact: true,
  },
  {
    path: '/relationship-types/:id/edit',
    loader: () =>
      import('src/view/relationshipTypes/form/RelationshipTypesFormPage'),
    permissionRequired: permissions.relationshipTypesEdit,
    exact: true,
  },
  {
    path: '/relationship-types/:id',
    loader: () =>
      import('src/view/relationshipTypes/view/RelationshipTypesViewPage'),
    permissionRequired: permissions.relationshipTypesRead,
    exact: true,
  },

  {
    path: '/services',
    loader: () =>
      import('src/view/services/list/ServicesListPage'),
    permissionRequired: permissions.servicesRead,
    exact: true,
  },
  {
    path: '/services/new',
    loader: () =>
      import('src/view/services/form/ServicesFormPage'),
    permissionRequired: permissions.servicesCreate,
    exact: true,
  },
  {
    path: '/services/importer',
    loader: () =>
      import(
        'src/view/services/importer/ServicesImporterPage'
      ),
    permissionRequired: permissions.servicesImport,
    exact: true,
  },
  {
    path: '/services/:id/edit',
    loader: () =>
      import('src/view/services/form/ServicesFormPage'),
    permissionRequired: permissions.servicesEdit,
    exact: true,
  },
  {
    path: '/services/:id',
    loader: () =>
      import('src/view/services/view/ServicesViewPage'),
    permissionRequired: permissions.servicesRead,
    exact: true,
  },

  {
    path: '/announcements',
    loader: () =>
      import('src/view/announcements/list/AnnouncementsListPage'),
    permissionRequired: permissions.announcementsRead,
    exact: true,
  },
  {
    path: '/announcements/new',
    loader: () =>
      import('src/view/announcements/form/AnnouncementsFormPage'),
    permissionRequired: permissions.announcementsCreate,
    exact: true,
  },
  {
    path: '/announcements/importer',
    loader: () =>
      import(
        'src/view/announcements/importer/AnnouncementsImporterPage'
      ),
    permissionRequired: permissions.announcementsImport,
    exact: true,
  },
  {
    path: '/announcements/:id/edit',
    loader: () =>
      import('src/view/announcements/form/AnnouncementsFormPage'),
    permissionRequired: permissions.announcementsEdit,
    exact: true,
  },
  {
    path: '/announcements/:id',
    loader: () =>
      import('src/view/announcements/view/AnnouncementsViewPage'),
    permissionRequired: permissions.announcementsRead,
    exact: true,
  },

  {
    path: '/sermons',
    loader: () =>
      import('src/view/sermons/list/SermonsListPage'),
    permissionRequired: permissions.sermonsRead,
    exact: true,
  },
  {
    path: '/sermons/new',
    loader: () =>
      import('src/view/sermons/form/SermonsFormPage'),
    permissionRequired: permissions.sermonsCreate,
    exact: true,
  },
  {
    path: '/sermons/importer',
    loader: () =>
      import(
        'src/view/sermons/importer/SermonsImporterPage'
      ),
    permissionRequired: permissions.sermonsImport,
    exact: true,
  },
  {
    path: '/sermons/:id/edit',
    loader: () =>
      import('src/view/sermons/form/SermonsFormPage'),
    permissionRequired: permissions.sermonsEdit,
    exact: true,
  },
  {
    path: '/sermons/:id',
    loader: () =>
      import('src/view/sermons/view/SermonsViewPage'),
    permissionRequired: permissions.sermonsRead,
    exact: true,
  },

  {
    path: '/readings',
    loader: () =>
      import('src/view/readings/list/ReadingsListPage'),
    permissionRequired: permissions.readingsRead,
    exact: true,
  },
  {
    path: '/readings/new',
    loader: () =>
      import('src/view/readings/form/ReadingsFormPage'),
    permissionRequired: permissions.readingsCreate,
    exact: true,
  },
  {
    path: '/readings/importer',
    loader: () =>
      import(
        'src/view/readings/importer/ReadingsImporterPage'
      ),
    permissionRequired: permissions.readingsImport,
    exact: true,
  },
  {
    path: '/readings/:id/edit',
    loader: () =>
      import('src/view/readings/form/ReadingsFormPage'),
    permissionRequired: permissions.readingsEdit,
    exact: true,
  },
  {
    path: '/readings/:id',
    loader: () =>
      import('src/view/readings/view/ReadingsViewPage'),
    permissionRequired: permissions.readingsRead,
    exact: true,
  },

  {
    path: '/responsorial-psalms',
    loader: () =>
      import('src/view/responsorialPsalms/list/ResponsorialPsalmsListPage'),
    permissionRequired: permissions.responsorialPsalmsRead,
    exact: true,
  },
  {
    path: '/responsorial-psalms/new',
    loader: () =>
      import('src/view/responsorialPsalms/form/ResponsorialPsalmsFormPage'),
    permissionRequired: permissions.responsorialPsalmsCreate,
    exact: true,
  },
  {
    path: '/responsorial-psalms/importer',
    loader: () =>
      import(
        'src/view/responsorialPsalms/importer/ResponsorialPsalmsImporterPage'
      ),
    permissionRequired: permissions.responsorialPsalmsImport,
    exact: true,
  },
  {
    path: '/responsorial-psalms/:id/edit',
    loader: () =>
      import('src/view/responsorialPsalms/form/ResponsorialPsalmsFormPage'),
    permissionRequired: permissions.responsorialPsalmsEdit,
    exact: true,
  },
  {
    path: '/responsorial-psalms/:id',
    loader: () =>
      import('src/view/responsorialPsalms/view/ResponsorialPsalmsViewPage'),
    permissionRequired: permissions.responsorialPsalmsRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
