import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'parkingSessionName',
    label: i18n('entities.parkingSessions.fields.parkingSessionName'),
    schema: schemas.string(
      i18n('entities.parkingSessions.fields.parkingSessionName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'parkingSessionStartTime',
    label: i18n('entities.parkingSessions.fields.parkingSessionStartTime'),
    schema: schemas.datetime(
      i18n('entities.parkingSessions.fields.parkingSessionStartTime'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'parkingSessionEndTime',
    label: i18n('entities.parkingSessions.fields.parkingSessionEndTime'),
    schema: schemas.datetime(
      i18n('entities.parkingSessions.fields.parkingSessionEndTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'parkingSessionParkings',
    label: i18n('entities.parkingSessions.fields.parkingSessionParkings'),
    schema: schemas.relationToMany(
      i18n('entities.parkingSessions.fields.parkingSessionParkings'),
      {},
    ),
  },
];