import list from 'src/modules/contributionTypes/list/contributionTypesListReducers';
import form from 'src/modules/contributionTypes/form/contributionTypesFormReducers';
import view from 'src/modules/contributionTypes/view/contributionTypesViewReducers';
import destroy from 'src/modules/contributionTypes/destroy/contributionTypesDestroyReducers';
import importerReducer from 'src/modules/contributionTypes/importer/contributionTypesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
