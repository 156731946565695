import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'relationshipFirstCongregant',
    label: i18n('entities.relationships.fields.relationshipFirstCongregant'),
    schema: schemas.relationToOne(
      i18n('entities.relationships.fields.relationshipFirstCongregant'),
      {},
    ),
  },
  {
    name: 'relationshipRelationshipType',
    label: i18n('entities.relationships.fields.relationshipRelationshipType'),
    schema: schemas.relationToOne(
      i18n('entities.relationships.fields.relationshipRelationshipType'),
      {},
    ),
  },
  {
    name: 'relationshipSecondCongregant',
    label: i18n('entities.relationships.fields.relationshipSecondCongregant'),
    schema: schemas.relationToOne(
      i18n('entities.relationships.fields.relationshipSecondCongregant'),
      {},
    ),
  },
];