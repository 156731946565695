import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.assets.fields.id'),
  },
  {
    name: 'assetName',
    label: i18n('entities.assets.fields.assetName'),
  },
  {
    name: 'assetDescription',
    label: i18n('entities.assets.fields.assetDescription'),
  },
  {
    name: 'assetTotalInitialQuantity',
    label: i18n('entities.assets.fields.assetTotalInitialQuantity'),
  },
  {
    name: 'assetInitialValue',
    label: i18n('entities.assets.fields.assetInitialValue'),
  },
  {
    name: 'assetIncrease',
    label: i18n('entities.assets.fields.assetIncrease'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'assetDecrease',
    label: i18n('entities.assets.fields.assetDecrease'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'assetTotalCurrentQuantity',
    label: i18n('entities.assets.fields.assetTotalCurrentQuantity'),
  },
  {
    name: 'assetCurrentValue',
    label: i18n('entities.assets.fields.assetCurrentValue'),
  },
  {
    name: 'assetExpenses',
    label: i18n('entities.assets.fields.assetExpenses'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.assets.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.assets.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
