import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'contributionID',
    label: i18n('entities.contributions.fields.contributionID'),
    schema: schemas.string(
      i18n('entities.contributions.fields.contributionID'),
      {},
    ),
  },
  {
    name: 'contributionType',
    label: i18n('entities.contributions.fields.contributionType'),
    schema: schemas.relationToOne(
      i18n('entities.contributions.fields.contributionType'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'contributionOfCongregant',
    label: i18n('entities.contributions.fields.contributionOfCongregant'),
    schema: schemas.relationToOne(
      i18n('entities.contributions.fields.contributionOfCongregant'),
      {},
    ),
  },
  {
    name: 'contributionOfGroup',
    label: i18n('entities.contributions.fields.contributionOfGroup'),
    schema: schemas.relationToOne(
      i18n('entities.contributions.fields.contributionOfGroup'),
      {},
    ),
  },
  {
    name: 'contributionTowardsProject',
    label: i18n('entities.contributions.fields.contributionTowardsProject'),
    schema: schemas.relationToOne(
      i18n('entities.contributions.fields.contributionTowardsProject'),
      {},
    ),
  },
  {
    name: 'contributionTowardsEvent',
    label: i18n('entities.contributions.fields.contributionTowardsEvent'),
    schema: schemas.relationToOne(
      i18n('entities.contributions.fields.contributionTowardsEvent'),
      {},
    ),
  },
  {
    name: 'contributionService',
    label: i18n('entities.contributions.fields.contributionService'),
    schema: schemas.relationToOne(
      i18n('entities.contributions.fields.contributionService'),
      {},
    ),
  },
];