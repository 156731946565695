import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'contributionTypeName',
    label: i18n('entities.contributionTypes.fields.contributionTypeName'),
    schema: schemas.string(
      i18n('entities.contributionTypes.fields.contributionTypeName'),
      {
        "required": true,
        "min": 1
      },
    ),
  },
  {
    name: 'contributionTypeDescription',
    label: i18n('entities.contributionTypes.fields.contributionTypeDescription'),
    schema: schemas.string(
      i18n('entities.contributionTypes.fields.contributionTypeDescription'),
      {},
    ),
  },
  {
    name: 'contributionTypeContributions',
    label: i18n('entities.contributionTypes.fields.contributionTypeContributions'),
    schema: schemas.relationToMany(
      i18n('entities.contributionTypes.fields.contributionTypeContributions'),
      {},
    ),
  },
];