import list from 'src/modules/responsorialPsalms/list/responsorialPsalmsListReducers';
import form from 'src/modules/responsorialPsalms/form/responsorialPsalmsFormReducers';
import view from 'src/modules/responsorialPsalms/view/responsorialPsalmsViewReducers';
import destroy from 'src/modules/responsorialPsalms/destroy/responsorialPsalmsDestroyReducers';
import importerReducer from 'src/modules/responsorialPsalms/importer/responsorialPsalmsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
