import list from 'src/modules/expenses/list/expensesListReducers';
import form from 'src/modules/expenses/form/expensesFormReducers';
import view from 'src/modules/expenses/view/expensesViewReducers';
import destroy from 'src/modules/expenses/destroy/expensesDestroyReducers';
import importerReducer from 'src/modules/expenses/importer/expensesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
