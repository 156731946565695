import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/responsorialPsalms/importer/responsorialPsalmsImporterSelectors';
import ResponsorialPsalmsService from 'src/modules/responsorialPsalms/responsorialPsalmsService';
import fields from 'src/modules/responsorialPsalms/importer/responsorialPsalmsImporterFields';
import { i18n } from 'src/i18n';

const responsorialPsalmsImporterActions = importerActions(
  'RESPONSORIALPSALMS_IMPORTER',
  selectors,
  ResponsorialPsalmsService.import,
  fields,
  i18n('entities.responsorialPsalms.importer.fileName'),
);

export default responsorialPsalmsImporterActions;