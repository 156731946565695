import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'relationshipTypeName',
    label: i18n('entities.relationshipTypes.fields.relationshipTypeName'),
    schema: schemas.string(
      i18n('entities.relationshipTypes.fields.relationshipTypeName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'relationshipTypeInverseName',
    label: i18n('entities.relationshipTypes.fields.relationshipTypeInverseName'),
    schema: schemas.string(
      i18n('entities.relationshipTypes.fields.relationshipTypeInverseName'),
      {},
    ),
  },
  {
    name: 'relationshipTypeRelationships',
    label: i18n('entities.relationshipTypes.fields.relationshipTypeRelationships'),
    schema: schemas.relationToMany(
      i18n('entities.relationshipTypes.fields.relationshipTypeRelationships'),
      {},
    ),
  },
];