import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'serviceName',
    label: i18n('entities.services.fields.serviceName'),
    schema: schemas.string(
      i18n('entities.services.fields.serviceName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'serviceDate',
    label: i18n('entities.services.fields.serviceDate'),
    schema: schemas.datetime(
      i18n('entities.services.fields.serviceDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'serviceAnnouncement',
    label: i18n('entities.services.fields.serviceAnnouncement'),
    schema: schemas.relationToMany(
      i18n('entities.services.fields.serviceAnnouncement'),
      {},
    ),
  },
  {
    name: 'serviceSermon',
    label: i18n('entities.services.fields.serviceSermon'),
    schema: schemas.relationToMany(
      i18n('entities.services.fields.serviceSermon'),
      {},
    ),
  },
  {
    name: 'serviceContributions',
    label: i18n('entities.services.fields.serviceContributions'),
    schema: schemas.relationToMany(
      i18n('entities.services.fields.serviceContributions'),
      {},
    ),
  },
  {
    name: 'serviceLeadingGroup',
    label: i18n('entities.services.fields.serviceLeadingGroup'),
    schema: schemas.relationToMany(
      i18n('entities.services.fields.serviceLeadingGroup'),
      {},
    ),
  },
];