import listActions from 'src/modules/congregant/list/congregantListActions';
import CongregantService from 'src/modules/congregant/congregantService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'CONGREGANT_DESTROY';

const congregantDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: congregantDestroyActions.DESTROY_STARTED,
      });

      await CongregantService.destroyAll([id]);

      dispatch({
        type: congregantDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.congregant.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/congregant');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: congregantDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: congregantDestroyActions.DESTROY_ALL_STARTED,
      });

      await CongregantService.destroyAll(ids);

      dispatch({
        type: congregantDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.congregant.destroyAll.success'),
      );

      getHistory().push('/congregant');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: congregantDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default congregantDestroyActions;
