import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/projects/importer/projectsImporterSelectors';
import ProjectsService from 'src/modules/projects/projectsService';
import fields from 'src/modules/projects/importer/projectsImporterFields';
import { i18n } from 'src/i18n';

const projectsImporterActions = importerActions(
  'PROJECTS_IMPORTER',
  selectors,
  ProjectsService.import,
  fields,
  i18n('entities.projects.importer.fileName'),
);

export default projectsImporterActions;