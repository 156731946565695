import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/assetDecrements/importer/assetDecrementsImporterSelectors';
import AssetDecrementsService from 'src/modules/assetDecrements/assetDecrementsService';
import fields from 'src/modules/assetDecrements/importer/assetDecrementsImporterFields';
import { i18n } from 'src/i18n';

const assetDecrementsImporterActions = importerActions(
  'ASSETDECREMENTS_IMPORTER',
  selectors,
  AssetDecrementsService.import,
  fields,
  i18n('entities.assetDecrements.importer.fileName'),
);

export default assetDecrementsImporterActions;