import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/parkingSessions/importer/parkingSessionsImporterSelectors';
import ParkingSessionsService from 'src/modules/parkingSessions/parkingSessionsService';
import fields from 'src/modules/parkingSessions/importer/parkingSessionsImporterFields';
import { i18n } from 'src/i18n';

const parkingSessionsImporterActions = importerActions(
  'PARKINGSESSIONS_IMPORTER',
  selectors,
  ParkingSessionsService.import,
  fields,
  i18n('entities.parkingSessions.importer.fileName'),
);

export default parkingSessionsImporterActions;