import list from 'src/modules/relationshipTypes/list/relationshipTypesListReducers';
import form from 'src/modules/relationshipTypes/form/relationshipTypesFormReducers';
import view from 'src/modules/relationshipTypes/view/relationshipTypesViewReducers';
import destroy from 'src/modules/relationshipTypes/destroy/relationshipTypesDestroyReducers';
import importerReducer from 'src/modules/relationshipTypes/importer/relationshipTypesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
