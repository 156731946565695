import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'assetDecrementReason',
    label: i18n('entities.assetDecrements.fields.assetDecrementReason'),
    schema: schemas.string(
      i18n('entities.assetDecrements.fields.assetDecrementReason'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'assetDecrementQuantity',
    label: i18n('entities.assetDecrements.fields.assetDecrementQuantity'),
    schema: schemas.integer(
      i18n('entities.assetDecrements.fields.assetDecrementQuantity'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'assetDecrementOfAsset',
    label: i18n('entities.assetDecrements.fields.assetDecrementOfAsset'),
    schema: schemas.relationToOne(
      i18n('entities.assetDecrements.fields.assetDecrementOfAsset'),
      {},
    ),
  },
];