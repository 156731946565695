import list from 'src/modules/assetDecrements/list/assetDecrementsListReducers';
import form from 'src/modules/assetDecrements/form/assetDecrementsFormReducers';
import view from 'src/modules/assetDecrements/view/assetDecrementsViewReducers';
import destroy from 'src/modules/assetDecrements/destroy/assetDecrementsDestroyReducers';
import importerReducer from 'src/modules/assetDecrements/importer/assetDecrementsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
