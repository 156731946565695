import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/assetIncrements/importer/assetIncrementsImporterSelectors';
import AssetIncrementsService from 'src/modules/assetIncrements/assetIncrementsService';
import fields from 'src/modules/assetIncrements/importer/assetIncrementsImporterFields';
import { i18n } from 'src/i18n';

const assetIncrementsImporterActions = importerActions(
  'ASSETINCREMENTS_IMPORTER',
  selectors,
  AssetIncrementsService.import,
  fields,
  i18n('entities.assetIncrements.importer.fileName'),
);

export default assetIncrementsImporterActions;