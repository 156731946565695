import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import congregant from 'src/modules/congregant/congregantReducers';
import membershipType from 'src/modules/membershipType/membershipTypeReducers';
import groups from 'src/modules/groups/groupsReducers';
import projects from 'src/modules/projects/projectsReducers';
import contributions from 'src/modules/contributions/contributionsReducers';
import contributionTypes from 'src/modules/contributionTypes/contributionTypesReducers';
import events from 'src/modules/events/eventsReducers';
import documents from 'src/modules/documents/documentsReducers';
import documentTypes from 'src/modules/documentTypes/documentTypesReducers';
import assets from 'src/modules/assets/assetsReducers';
import assetIncrements from 'src/modules/assetIncrements/assetIncrementsReducers';
import assetDecrements from 'src/modules/assetDecrements/assetDecrementsReducers';
import expenses from 'src/modules/expenses/expensesReducers';
import messages from 'src/modules/messages/messagesReducers';
import parkings from 'src/modules/parkings/parkingsReducers';
import parkingSessions from 'src/modules/parkingSessions/parkingSessionsReducers';
import cars from 'src/modules/cars/carsReducers';
import relationships from 'src/modules/relationships/relationshipsReducers';
import relationshipTypes from 'src/modules/relationshipTypes/relationshipTypesReducers';
import services from 'src/modules/services/servicesReducers';
import announcements from 'src/modules/announcements/announcementsReducers';
import sermons from 'src/modules/sermons/sermonsReducers';
import readings from 'src/modules/readings/readingsReducers';
import responsorialPsalms from 'src/modules/responsorialPsalms/responsorialPsalmsReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    congregant,
    membershipType,
    groups,
    projects,
    contributions,
    contributionTypes,
    events,
    documents,
    documentTypes,
    assets,
    assetIncrements,
    assetDecrements,
    expenses,
    messages,
    parkings,
    parkingSessions,
    cars,
    relationships,
    relationshipTypes,
    services,
    announcements,
    sermons,
    readings,
    responsorialPsalms,
  });
