import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/documents/importer/documentsImporterSelectors';
import DocumentsService from 'src/modules/documents/documentsService';
import fields from 'src/modules/documents/importer/documentsImporterFields';
import { i18n } from 'src/i18n';

const documentsImporterActions = importerActions(
  'DOCUMENTS_IMPORTER',
  selectors,
  DocumentsService.import,
  fields,
  i18n('entities.documents.importer.fileName'),
);

export default documentsImporterActions;