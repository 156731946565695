import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'documentTypeName',
    label: i18n('entities.documentTypes.fields.documentTypeName'),
    schema: schemas.string(
      i18n('entities.documentTypes.fields.documentTypeName'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'documentTypeDescription',
    label: i18n('entities.documentTypes.fields.documentTypeDescription'),
    schema: schemas.string(
      i18n('entities.documentTypes.fields.documentTypeDescription'),
      {},
    ),
  },
  {
    name: 'documentTypeDocuments',
    label: i18n('entities.documentTypes.fields.documentTypeDocuments'),
    schema: schemas.relationToMany(
      i18n('entities.documentTypes.fields.documentTypeDocuments'),
      {},
    ),
  },
];