import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/parkings/importer/parkingsImporterSelectors';
import ParkingsService from 'src/modules/parkings/parkingsService';
import fields from 'src/modules/parkings/importer/parkingsImporterFields';
import { i18n } from 'src/i18n';

const parkingsImporterActions = importerActions(
  'PARKINGS_IMPORTER',
  selectors,
  ParkingsService.import,
  fields,
  i18n('entities.parkings.importer.fileName'),
);

export default parkingsImporterActions;