import list from 'src/modules/contributions/list/contributionsListReducers';
import form from 'src/modules/contributions/form/contributionsFormReducers';
import view from 'src/modules/contributions/view/contributionsViewReducers';
import destroy from 'src/modules/contributions/destroy/contributionsDestroyReducers';
import importerReducer from 'src/modules/contributions/importer/contributionsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
