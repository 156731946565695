import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/contributionTypes/importer/contributionTypesImporterSelectors';
import ContributionTypesService from 'src/modules/contributionTypes/contributionTypesService';
import fields from 'src/modules/contributionTypes/importer/contributionTypesImporterFields';
import { i18n } from 'src/i18n';

const contributionTypesImporterActions = importerActions(
  'CONTRIBUTIONTYPES_IMPORTER',
  selectors,
  ContributionTypesService.import,
  fields,
  i18n('entities.contributionTypes.importer.fileName'),
);

export default contributionTypesImporterActions;