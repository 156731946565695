import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.parkings.fields.id'),
  },
  {
    name: 'parkingTitle',
    label: i18n('entities.parkings.fields.parkingTitle'),
  },
  {
    name: 'parkingStartTime',
    label: i18n('entities.parkings.fields.parkingStartTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'parkingEndTime',
    label: i18n('entities.parkings.fields.parkingEndTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'parkingDriversPhoneNumber',
    label: i18n('entities.parkings.fields.parkingDriversPhoneNumber'),
  },
  {
    name: 'parkingByCongregant',
    label: i18n('entities.parkings.fields.parkingByCongregant'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'parkingCars',
    label: i18n('entities.parkings.fields.parkingCars'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'parkingParkingSession',
    label: i18n('entities.parkings.fields.parkingParkingSession'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.parkings.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.parkings.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
