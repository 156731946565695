import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.groups.fields.id'),
  },
  {
    name: 'groupName',
    label: i18n('entities.groups.fields.groupName'),
  },
  {
    name: 'groupDescription',
    label: i18n('entities.groups.fields.groupDescription'),
  },
  {
    name: 'groupCongregationMembers',
    label: i18n('entities.groups.fields.groupCongregationMembers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'groupProjects',
    label: i18n('entities.groups.fields.groupProjects'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'groupContributions',
    label: i18n('entities.groups.fields.groupContributions'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'groupEvents',
    label: i18n('entities.groups.fields.groupEvents'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'groupDocuments',
    label: i18n('entities.groups.fields.groupDocuments'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'groupExpenses',
    label: i18n('entities.groups.fields.groupExpenses'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'groupMessagesSent',
    label: i18n('entities.groups.fields.groupMessagesSent'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'groupLeadServices',
    label: i18n('entities.groups.fields.groupLeadServices'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.groups.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.groups.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
