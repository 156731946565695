import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.projects.fields.id'),
  },
  {
    name: 'projectName',
    label: i18n('entities.projects.fields.projectName'),
  },
  {
    name: 'projectDescription',
    label: i18n('entities.projects.fields.projectDescription'),
  },
  {
    name: 'projectBudget',
    label: i18n('entities.projects.fields.projectBudget'),
  },
  {
    name: 'projectOfGroup',
    label: i18n('entities.projects.fields.projectOfGroup'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'projectContributions',
    label: i18n('entities.projects.fields.projectContributions'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'projectTotalContributions',
    label: i18n('entities.projects.fields.projectTotalContributions'),
  },
  {
    name: 'projectDocuments',
    label: i18n('entities.projects.fields.projectDocuments'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'projectExpenses',
    label: i18n('entities.projects.fields.projectExpenses'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.projects.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.projects.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
