import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.relationshipTypes.fields.id'),
  },
  {
    name: 'relationshipTypeName',
    label: i18n('entities.relationshipTypes.fields.relationshipTypeName'),
  },
  {
    name: 'relationshipTypeInverseName',
    label: i18n('entities.relationshipTypes.fields.relationshipTypeInverseName'),
  },
  {
    name: 'relationshipTypeRelationships',
    label: i18n('entities.relationshipTypes.fields.relationshipTypeRelationships'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.relationshipTypes.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.relationshipTypes.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
