import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/cars/importer/carsImporterSelectors';
import CarsService from 'src/modules/cars/carsService';
import fields from 'src/modules/cars/importer/carsImporterFields';
import { i18n } from 'src/i18n';

const carsImporterActions = importerActions(
  'CARS_IMPORTER',
  selectors,
  CarsService.import,
  fields,
  i18n('entities.cars.importer.fileName'),
);

export default carsImporterActions;