import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import messagesEnumerators from 'src/modules/messages/messagesEnumerators';

export default [
  {
    name: 'messageTitle',
    label: i18n('entities.messages.fields.messageTitle'),
    schema: schemas.string(
      i18n('entities.messages.fields.messageTitle'),
      {
        "required": true,
        "min": 3
      },
    ),
  },
  {
    name: 'messageContent',
    label: i18n('entities.messages.fields.messageContent'),
    schema: schemas.string(
      i18n('entities.messages.fields.messageContent'),
      {
        "required": true,
        "min": 3
      },
    ),
  },
  {
    name: 'messageType',
    label: i18n('entities.messages.fields.messageType'),
    schema: schemas.enumerator(
      i18n('entities.messages.fields.messageType'),
      {
        "options": messagesEnumerators.messageType
      },
    ),
  },
  {
    name: 'messageToCongregant',
    label: i18n('entities.messages.fields.messageToCongregant'),
    schema: schemas.relationToMany(
      i18n('entities.messages.fields.messageToCongregant'),
      {},
    ),
  },
  {
    name: 'messageToGroup',
    label: i18n('entities.messages.fields.messageToGroup'),
    schema: schemas.relationToMany(
      i18n('entities.messages.fields.messageToGroup'),
      {},
    ),
  },
];