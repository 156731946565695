import list from 'src/modules/documents/list/documentsListReducers';
import form from 'src/modules/documents/form/documentsFormReducers';
import view from 'src/modules/documents/view/documentsViewReducers';
import destroy from 'src/modules/documents/destroy/documentsDestroyReducers';
import importerReducer from 'src/modules/documents/importer/documentsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
