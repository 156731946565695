import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/contributions/importer/contributionsImporterSelectors';
import ContributionsService from 'src/modules/contributions/contributionsService';
import fields from 'src/modules/contributions/importer/contributionsImporterFields';
import { i18n } from 'src/i18n';

const contributionsImporterActions = importerActions(
  'CONTRIBUTIONS_IMPORTER',
  selectors,
  ContributionsService.import,
  fields,
  i18n('entities.contributions.importer.fileName'),
);

export default contributionsImporterActions;