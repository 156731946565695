import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'assetName',
    label: i18n('entities.assets.fields.assetName'),
    schema: schemas.string(
      i18n('entities.assets.fields.assetName'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'assetDescription',
    label: i18n('entities.assets.fields.assetDescription'),
    schema: schemas.string(
      i18n('entities.assets.fields.assetDescription'),
      {},
    ),
  },
  {
    name: 'assetTotalInitialQuantity',
    label: i18n('entities.assets.fields.assetTotalInitialQuantity'),
    schema: schemas.integer(
      i18n('entities.assets.fields.assetTotalInitialQuantity'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'assetInitialValue',
    label: i18n('entities.assets.fields.assetInitialValue'),
    schema: schemas.integer(
      i18n('entities.assets.fields.assetInitialValue'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'assetIncrease',
    label: i18n('entities.assets.fields.assetIncrease'),
    schema: schemas.relationToMany(
      i18n('entities.assets.fields.assetIncrease'),
      {},
    ),
  },
  {
    name: 'assetDecrease',
    label: i18n('entities.assets.fields.assetDecrease'),
    schema: schemas.relationToMany(
      i18n('entities.assets.fields.assetDecrease'),
      {},
    ),
  },
  {
    name: 'assetTotalCurrentQuantity',
    label: i18n('entities.assets.fields.assetTotalCurrentQuantity'),
    schema: schemas.integer(
      i18n('entities.assets.fields.assetTotalCurrentQuantity'),
      {},
    ),
  },
  {
    name: 'assetCurrentValue',
    label: i18n('entities.assets.fields.assetCurrentValue'),
    schema: schemas.integer(
      i18n('entities.assets.fields.assetCurrentValue'),
      {},
    ),
  },
  {
    name: 'assetExpenses',
    label: i18n('entities.assets.fields.assetExpenses'),
    schema: schemas.relationToMany(
      i18n('entities.assets.fields.assetExpenses'),
      {},
    ),
  },
];