import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/groups/importer/groupsImporterSelectors';
import GroupsService from 'src/modules/groups/groupsService';
import fields from 'src/modules/groups/importer/groupsImporterFields';
import { i18n } from 'src/i18n';

const groupsImporterActions = importerActions(
  'GROUPS_IMPORTER',
  selectors,
  GroupsService.import,
  fields,
  i18n('entities.groups.importer.fileName'),
);

export default groupsImporterActions;