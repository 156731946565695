import list from 'src/modules/assetIncrements/list/assetIncrementsListReducers';
import form from 'src/modules/assetIncrements/form/assetIncrementsFormReducers';
import view from 'src/modules/assetIncrements/view/assetIncrementsViewReducers';
import destroy from 'src/modules/assetIncrements/destroy/assetIncrementsDestroyReducers';
import importerReducer from 'src/modules/assetIncrements/importer/assetIncrementsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
