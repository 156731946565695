import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'assetIncrementReason',
    label: i18n('entities.assetIncrements.fields.assetIncrementReason'),
    schema: schemas.string(
      i18n('entities.assetIncrements.fields.assetIncrementReason'),
      {},
    ),
  },
  {
    name: 'assetIncrementQuantity',
    label: i18n('entities.assetIncrements.fields.assetIncrementQuantity'),
    schema: schemas.integer(
      i18n('entities.assetIncrements.fields.assetIncrementQuantity'),
      {},
    ),
  },
  {
    name: 'assetIncrementOfAsset',
    label: i18n('entities.assetIncrements.fields.assetIncrementOfAsset'),
    schema: schemas.relationToOne(
      i18n('entities.assetIncrements.fields.assetIncrementOfAsset'),
      {},
    ),
  },
];