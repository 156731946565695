import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'eventName',
    label: i18n('entities.events.fields.eventName'),
    schema: schemas.string(
      i18n('entities.events.fields.eventName'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'eventDescription',
    label: i18n('entities.events.fields.eventDescription'),
    schema: schemas.string(
      i18n('entities.events.fields.eventDescription'),
      {},
    ),
  },
  {
    name: 'eventDate',
    label: i18n('entities.events.fields.eventDate'),
    schema: schemas.datetime(
      i18n('entities.events.fields.eventDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'eventBudget',
    label: i18n('entities.events.fields.eventBudget'),
    schema: schemas.integer(
      i18n('entities.events.fields.eventBudget'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'eventAttendingCongregants',
    label: i18n('entities.events.fields.eventAttendingCongregants'),
    schema: schemas.relationToMany(
      i18n('entities.events.fields.eventAttendingCongregants'),
      {},
    ),
  },
  {
    name: 'eventOfGroup',
    label: i18n('entities.events.fields.eventOfGroup'),
    schema: schemas.relationToMany(
      i18n('entities.events.fields.eventOfGroup'),
      {},
    ),
  },
  {
    name: 'eventContributions',
    label: i18n('entities.events.fields.eventContributions'),
    schema: schemas.relationToMany(
      i18n('entities.events.fields.eventContributions'),
      {},
    ),
  },
  {
    name: 'eventTotalContributions',
    label: i18n('entities.events.fields.eventTotalContributions'),
    schema: schemas.integer(
      i18n('entities.events.fields.eventTotalContributions'),
      {},
    ),
  },
  {
    name: 'eventDocuments',
    label: i18n('entities.events.fields.eventDocuments'),
    schema: schemas.relationToMany(
      i18n('entities.events.fields.eventDocuments'),
      {},
    ),
  },
  {
    name: 'eventExpenses',
    label: i18n('entities.events.fields.eventExpenses'),
    schema: schemas.relationToMany(
      i18n('entities.events.fields.eventExpenses'),
      {},
    ),
  },
];