import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import congregantEnumerators from 'src/modules/congregant/congregantEnumerators';
import moment from 'moment';

export default [
  {
    name: 'congregantSurname',
    label: i18n('entities.congregant.fields.congregantSurname'),
    schema: schemas.string(
      i18n('entities.congregant.fields.congregantSurname'),
      {
        "required": true,
        "min": 2,
        "max": 255
      },
    ),
  },
  {
    name: 'congregantFirstName',
    label: i18n('entities.congregant.fields.congregantFirstName'),
    schema: schemas.string(
      i18n('entities.congregant.fields.congregantFirstName'),
      {
        "required": true,
        "min": 2,
        "max": 255
      },
    ),
  },
  {
    name: 'congregantMiddleName',
    label: i18n('entities.congregant.fields.congregantMiddleName'),
    schema: schemas.string(
      i18n('entities.congregant.fields.congregantMiddleName'),
      {},
    ),
  },
  {
    name: 'congregantAlias',
    label: i18n('entities.congregant.fields.congregantAlias'),
    schema: schemas.string(
      i18n('entities.congregant.fields.congregantAlias'),
      {},
    ),
  },
  {
    name: 'congregantPhoneNumber',
    label: i18n('entities.congregant.fields.congregantPhoneNumber'),
    schema: schemas.string(
      i18n('entities.congregant.fields.congregantPhoneNumber'),
      {
        "required": true,
        "min": 10
      },
    ),
  },
  {
    name: 'congregantEmail',
    label: i18n('entities.congregant.fields.congregantEmail'),
    schema: schemas.string(
      i18n('entities.congregant.fields.congregantEmail'),
      {},
    ),
  },
  {
    name: 'congregantBirthdate',
    label: i18n('entities.congregant.fields.congregantBirthdate'),
    schema: schemas.date(
      i18n('entities.congregant.fields.congregantBirthdate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'congregantGender',
    label: i18n('entities.congregant.fields.congregantGender'),
    schema: schemas.enumerator(
      i18n('entities.congregant.fields.congregantGender'),
      {
        "options": congregantEnumerators.congregantGender
      },
    ),
  },
  {
    name: 'congregantMembershipType',
    label: i18n('entities.congregant.fields.congregantMembershipType'),
    schema: schemas.relationToOne(
      i18n('entities.congregant.fields.congregantMembershipType'),
      {},
    ),
  },
  {
    name: 'congregantAreaOfResidence',
    label: i18n('entities.congregant.fields.congregantAreaOfResidence'),
    schema: schemas.string(
      i18n('entities.congregant.fields.congregantAreaOfResidence'),
      {},
    ),
  },
  {
    name: 'congregantContributions',
    label: i18n('entities.congregant.fields.congregantContributions'),
    schema: schemas.relationToMany(
      i18n('entities.congregant.fields.congregantContributions'),
      {},
    ),
  },
  {
    name: 'congregantGroup',
    label: i18n('entities.congregant.fields.congregantGroup'),
    schema: schemas.relationToMany(
      i18n('entities.congregant.fields.congregantGroup'),
      {},
    ),
  },
  {
    name: 'congregantRelationships',
    label: i18n('entities.congregant.fields.congregantRelationships'),
    schema: schemas.relationToMany(
      i18n('entities.congregant.fields.congregantRelationships'),
      {},
    ),
  },
  {
    name: 'congregantEventsAttended',
    label: i18n('entities.congregant.fields.congregantEventsAttended'),
    schema: schemas.relationToMany(
      i18n('entities.congregant.fields.congregantEventsAttended'),
      {},
    ),
  },
  {
    name: 'congregantDocument',
    label: i18n('entities.congregant.fields.congregantDocument'),
    schema: schemas.relationToMany(
      i18n('entities.congregant.fields.congregantDocument'),
      {},
    ),
  },
  {
    name: 'congregantMessagesSent',
    label: i18n('entities.congregant.fields.congregantMessagesSent'),
    schema: schemas.relationToMany(
      i18n('entities.congregant.fields.congregantMessagesSent'),
      {},
    ),
  },
  {
    name: 'congregantParkings',
    label: i18n('entities.congregant.fields.congregantParkings'),
    schema: schemas.relationToMany(
      i18n('entities.congregant.fields.congregantParkings'),
      {},
    ),
  },
  {
    name: 'congregantCars',
    label: i18n('entities.congregant.fields.congregantCars'),
    schema: schemas.relationToMany(
      i18n('entities.congregant.fields.congregantCars'),
      {},
    ),
  },
];