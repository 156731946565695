import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/membershipType/importer/membershipTypeImporterSelectors';
import MembershipTypeService from 'src/modules/membershipType/membershipTypeService';
import fields from 'src/modules/membershipType/importer/membershipTypeImporterFields';
import { i18n } from 'src/i18n';

const membershipTypeImporterActions = importerActions(
  'MEMBERSHIPTYPE_IMPORTER',
  selectors,
  MembershipTypeService.import,
  fields,
  i18n('entities.membershipType.importer.fileName'),
);

export default membershipTypeImporterActions;