import ResponsorialPsalmsService from 'src/modules/responsorialPsalms/responsorialPsalmsService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'RESPONSORIALPSALMS_VIEW';

const responsorialPsalmsViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: responsorialPsalmsViewActions.FIND_STARTED,
      });

      const record = await ResponsorialPsalmsService.find(id);

      dispatch({
        type: responsorialPsalmsViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: responsorialPsalmsViewActions.FIND_ERROR,
      });

      getHistory().push('/responsorial-psalms');
    }
  },
};

export default responsorialPsalmsViewActions;
