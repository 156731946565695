import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.relationships.fields.id'),
  },
  {
    name: 'relationshipFirstCongregant',
    label: i18n('entities.relationships.fields.relationshipFirstCongregant'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'relationshipRelationshipType',
    label: i18n('entities.relationships.fields.relationshipRelationshipType'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'relationshipSecondCongregant',
    label: i18n('entities.relationships.fields.relationshipSecondCongregant'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.relationships.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.relationships.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
