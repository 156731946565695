import listActions from 'src/modules/services/list/servicesListActions';
import ServicesService from 'src/modules/services/servicesService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'SERVICES_DESTROY';

const servicesDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: servicesDestroyActions.DESTROY_STARTED,
      });

      await ServicesService.destroyAll([id]);

      dispatch({
        type: servicesDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.services.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/services');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: servicesDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: servicesDestroyActions.DESTROY_ALL_STARTED,
      });

      await ServicesService.destroyAll(ids);

      dispatch({
        type: servicesDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.services.destroyAll.success'),
      );

      getHistory().push('/services');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: servicesDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default servicesDestroyActions;
