import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'carLicenseNoPlate',
    label: i18n('entities.cars.fields.carLicenseNoPlate'),
    schema: schemas.string(
      i18n('entities.cars.fields.carLicenseNoPlate'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'carOfCongregant',
    label: i18n('entities.cars.fields.carOfCongregant'),
    schema: schemas.relationToMany(
      i18n('entities.cars.fields.carOfCongregant'),
      {
        "max": 2
      },
    ),
  },
  {
    name: 'carParkings',
    label: i18n('entities.cars.fields.carParkings'),
    schema: schemas.relationToMany(
      i18n('entities.cars.fields.carParkings'),
      {},
    ),
  },
];