import list from 'src/modules/sermons/list/sermonsListReducers';
import form from 'src/modules/sermons/form/sermonsFormReducers';
import view from 'src/modules/sermons/view/sermonsViewReducers';
import destroy from 'src/modules/sermons/destroy/sermonsDestroyReducers';
import importerReducer from 'src/modules/sermons/importer/sermonsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
