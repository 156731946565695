import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import config from 'src/config';
import {
  faCalendar,
  faChevronRight,
  faCog,
  faCreditCard,
  faHistory,
  faHouseUser,
  faUserPlus,
  faProjectDiagram,
  faMoneyBill,
  faCalendarCheck,
  faFolder,
  faEnvelope,
  faBookOpen,
  faFile,
  faParking,
  faMoneyCheck,
  faCheckSquare,
  faTimesCircle,
  faCar,
  faBuilding,
  faMoneyBillWave,
  faFolderPlus,
  faSquare,
  faChurch,
  faCarAlt,
  faCross,
  faUserFriends,
  faUserAlt,
  faClipboardCheck,
  faMusic,
} from '@fortawesome/free-solid-svg-icons';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: faHouseUser,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },

  config.isPlanEnabled && {
    path: '/plan',
    permissionRequired: permissions.planRead,
    icon: faCreditCard,
    label: i18n('plan.menu'),
  },

  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: faUserPlus,
  },

  {
    path: '/calendar',
    icon: faCalendar,
    label: i18n('calendar.menu'),
    permissionRequired: permissions.calendarRead,
  },

  {
    path: '/audit-logs',
    icon: faHistory,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    icon: faCog,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/congregant',
    permissionRequired: permissions.congregantRead,
    icon: faUserAlt,
    label: i18n('entities.congregant.menu'),
  },

  {
    path: '/membership-type',
    permissionRequired: permissions.membershipTypeRead,
    icon: faClipboardCheck,
    label: i18n('entities.membershipType.menu'),
  },

  {
    path: '/groups',
    permissionRequired: permissions.groupsRead,
    icon: faUserFriends,
    label: i18n('entities.groups.menu'),
  },

  {
    path: '/projects',
    permissionRequired: permissions.projectsRead,
    icon: faBuilding,
    label: i18n('entities.projects.menu'),
  },

  {
    path: '/contributions',
    permissionRequired: permissions.contributionsRead,
    icon: faMoneyBill,
    label: i18n('entities.contributions.menu'),
  },

  {
    path: '/contribution-types',
    permissionRequired: permissions.contributionTypesRead,
    icon: faMoneyBillWave,
    label: i18n('entities.contributionTypes.menu'),
  },

  {
    path: '/events',
    permissionRequired: permissions.eventsRead,
    icon: faCalendarCheck,
    label: i18n('entities.events.menu'),
  },

  {
    path: '/documents',
    permissionRequired: permissions.documentsRead,
    icon: faFolder,
    label: i18n('entities.documents.menu'),
  },

  {
    path: '/document-types',
    permissionRequired: permissions.documentTypesRead,
    icon: faFolderPlus,
    label: i18n('entities.documentTypes.menu'),
  },

  {
    path: '/assets',
    permissionRequired: permissions.assetsRead,
    icon: faSquare,
    label: i18n('entities.assets.menu'),
  },

  {
    path: '/asset-increments',
    permissionRequired: permissions.assetIncrementsRead,
    icon: faCheckSquare,
    label: i18n('entities.assetIncrements.menu'),
  },

  {
    path: '/asset-decrements',
    permissionRequired: permissions.assetDecrementsRead,
    icon: faTimesCircle,
    label: i18n('entities.assetDecrements.menu'),
  },

  {
    path: '/expenses',
    permissionRequired: permissions.expensesRead,
    icon: faMoneyCheck,
    label: i18n('entities.expenses.menu'),
  },

  {
    path: '/messages',
    permissionRequired: permissions.messagesRead,
    icon: faEnvelope,
    label: i18n('entities.messages.menu'),
  },

  {
    path: '/parkings',
    permissionRequired: permissions.parkingsRead,
    icon: faParking,
    label: i18n('entities.parkings.menu'),
  },

  {
    path: '/parking-sessions',
    permissionRequired: permissions.parkingSessionsRead,
    icon: faCarAlt,
    label: i18n('entities.parkingSessions.menu'),
  },

  {
    path: '/cars',
    permissionRequired: permissions.carsRead,
    icon: faCar,
    label: i18n('entities.cars.menu'),
  },

  {
    path: '/relationships',
    permissionRequired: permissions.relationshipsRead,
    icon: faChevronRight,
    label: i18n('entities.relationships.menu'),
  },

  {
    path: '/relationship-types',
    permissionRequired: permissions.relationshipTypesRead,
    icon: faProjectDiagram,
    label: i18n('entities.relationshipTypes.menu'),
  },

  {
    path: '/services',
    permissionRequired: permissions.servicesRead,
    icon: faChurch,
    label: i18n('entities.services.menu'),
  },

  {
    path: '/announcements',
    permissionRequired: permissions.announcementsRead,
    icon: faFile,
    label: i18n('entities.announcements.menu'),
  },

  {
    path: '/sermons',
    permissionRequired: permissions.sermonsRead,
    icon: faCross,
    label: i18n('entities.sermons.menu'),
  },

  {
    path: '/readings',
    permissionRequired: permissions.readingsRead,
    icon: faBookOpen,
    label: i18n('entities.readings.menu'),
  },

  {
    path: '/responsorial-psalms',
    permissionRequired: permissions.responsorialPsalmsRead,
    icon: faMusic,
    label: i18n('entities.responsorialPsalms.menu'),
  },  
].filter(Boolean);
