import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.events.fields.id'),
  },
  {
    name: 'eventName',
    label: i18n('entities.events.fields.eventName'),
  },
  {
    name: 'eventDescription',
    label: i18n('entities.events.fields.eventDescription'),
  },
  {
    name: 'eventDate',
    label: i18n('entities.events.fields.eventDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'eventBudget',
    label: i18n('entities.events.fields.eventBudget'),
  },
  {
    name: 'eventAttendingCongregants',
    label: i18n('entities.events.fields.eventAttendingCongregants'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'eventOfGroup',
    label: i18n('entities.events.fields.eventOfGroup'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'eventContributions',
    label: i18n('entities.events.fields.eventContributions'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'eventTotalContributions',
    label: i18n('entities.events.fields.eventTotalContributions'),
  },
  {
    name: 'eventDocuments',
    label: i18n('entities.events.fields.eventDocuments'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'eventExpenses',
    label: i18n('entities.events.fields.eventExpenses'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.events.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.events.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
