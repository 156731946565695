import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'parkingTitle',
    label: i18n('entities.parkings.fields.parkingTitle'),
    schema: schemas.string(
      i18n('entities.parkings.fields.parkingTitle'),
      {
        "required": true,
        "min": 3
      },
    ),
  },
  {
    name: 'parkingStartTime',
    label: i18n('entities.parkings.fields.parkingStartTime'),
    schema: schemas.datetime(
      i18n('entities.parkings.fields.parkingStartTime'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'parkingEndTime',
    label: i18n('entities.parkings.fields.parkingEndTime'),
    schema: schemas.datetime(
      i18n('entities.parkings.fields.parkingEndTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'parkingDriversPhoneNumber',
    label: i18n('entities.parkings.fields.parkingDriversPhoneNumber'),
    schema: schemas.string(
      i18n('entities.parkings.fields.parkingDriversPhoneNumber'),
      {},
    ),
  },
  {
    name: 'parkingByCongregant',
    label: i18n('entities.parkings.fields.parkingByCongregant'),
    schema: schemas.relationToMany(
      i18n('entities.parkings.fields.parkingByCongregant'),
      {},
    ),
  },
  {
    name: 'parkingCars',
    label: i18n('entities.parkings.fields.parkingCars'),
    schema: schemas.relationToOne(
      i18n('entities.parkings.fields.parkingCars'),
      {},
    ),
  },
  {
    name: 'parkingParkingSession',
    label: i18n('entities.parkings.fields.parkingParkingSession'),
    schema: schemas.relationToOne(
      i18n('entities.parkings.fields.parkingParkingSession'),
      {},
    ),
  },
];