import list from 'src/modules/parkingSessions/list/parkingSessionsListReducers';
import form from 'src/modules/parkingSessions/form/parkingSessionsFormReducers';
import view from 'src/modules/parkingSessions/view/parkingSessionsViewReducers';
import destroy from 'src/modules/parkingSessions/destroy/parkingSessionsDestroyReducers';
import importerReducer from 'src/modules/parkingSessions/importer/parkingSessionsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
