import list from 'src/modules/readings/list/readingsListReducers';
import form from 'src/modules/readings/form/readingsFormReducers';
import view from 'src/modules/readings/view/readingsViewReducers';
import destroy from 'src/modules/readings/destroy/readingsDestroyReducers';
import importerReducer from 'src/modules/readings/importer/readingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
