import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'projectName',
    label: i18n('entities.projects.fields.projectName'),
    schema: schemas.string(
      i18n('entities.projects.fields.projectName'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'projectDescription',
    label: i18n('entities.projects.fields.projectDescription'),
    schema: schemas.string(
      i18n('entities.projects.fields.projectDescription'),
      {
        "required": true,
        "min": 3
      },
    ),
  },
  {
    name: 'projectBudget',
    label: i18n('entities.projects.fields.projectBudget'),
    schema: schemas.integer(
      i18n('entities.projects.fields.projectBudget'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'projectOfGroup',
    label: i18n('entities.projects.fields.projectOfGroup'),
    schema: schemas.relationToMany(
      i18n('entities.projects.fields.projectOfGroup'),
      {},
    ),
  },
  {
    name: 'projectContributions',
    label: i18n('entities.projects.fields.projectContributions'),
    schema: schemas.relationToMany(
      i18n('entities.projects.fields.projectContributions'),
      {},
    ),
  },
  {
    name: 'projectTotalContributions',
    label: i18n('entities.projects.fields.projectTotalContributions'),
    schema: schemas.integer(
      i18n('entities.projects.fields.projectTotalContributions'),
      {},
    ),
  },
  {
    name: 'projectDocuments',
    label: i18n('entities.projects.fields.projectDocuments'),
    schema: schemas.relationToMany(
      i18n('entities.projects.fields.projectDocuments'),
      {},
    ),
  },
  {
    name: 'projectExpenses',
    label: i18n('entities.projects.fields.projectExpenses'),
    schema: schemas.relationToMany(
      i18n('entities.projects.fields.projectExpenses'),
      {},
    ),
  },
];