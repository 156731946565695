import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'sermonTitle',
    label: i18n('entities.sermons.fields.sermonTitle'),
    schema: schemas.string(
      i18n('entities.sermons.fields.sermonTitle'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'sermonOfService',
    label: i18n('entities.sermons.fields.sermonOfService'),
    schema: schemas.relationToOne(
      i18n('entities.sermons.fields.sermonOfService'),
      {},
    ),
  },
  {
    name: 'sermonReadings',
    label: i18n('entities.sermons.fields.sermonReadings'),
    schema: schemas.relationToMany(
      i18n('entities.sermons.fields.sermonReadings'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'sermonDocuments',
    label: i18n('entities.sermons.fields.sermonDocuments'),
    schema: schemas.relationToMany(
      i18n('entities.sermons.fields.sermonDocuments'),
      {},
    ),
  },
];