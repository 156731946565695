import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/documentTypes/importer/documentTypesImporterSelectors';
import DocumentTypesService from 'src/modules/documentTypes/documentTypesService';
import fields from 'src/modules/documentTypes/importer/documentTypesImporterFields';
import { i18n } from 'src/i18n';

const documentTypesImporterActions = importerActions(
  'DOCUMENTTYPES_IMPORTER',
  selectors,
  DocumentTypesService.import,
  fields,
  i18n('entities.documentTypes.importer.fileName'),
);

export default documentTypesImporterActions;