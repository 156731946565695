import list from 'src/modules/relationships/list/relationshipsListReducers';
import form from 'src/modules/relationships/form/relationshipsFormReducers';
import view from 'src/modules/relationships/view/relationshipsViewReducers';
import destroy from 'src/modules/relationships/destroy/relationshipsDestroyReducers';
import importerReducer from 'src/modules/relationships/importer/relationshipsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
