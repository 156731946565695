import list from 'src/modules/documentTypes/list/documentTypesListReducers';
import form from 'src/modules/documentTypes/form/documentTypesFormReducers';
import view from 'src/modules/documentTypes/view/documentTypesViewReducers';
import destroy from 'src/modules/documentTypes/destroy/documentTypesDestroyReducers';
import importerReducer from 'src/modules/documentTypes/importer/documentTypesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
