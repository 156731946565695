import list from 'src/modules/congregant/list/congregantListReducers';
import form from 'src/modules/congregant/form/congregantFormReducers';
import view from 'src/modules/congregant/view/congregantViewReducers';
import destroy from 'src/modules/congregant/destroy/congregantDestroyReducers';
import importerReducer from 'src/modules/congregant/importer/congregantImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
