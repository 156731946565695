import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/relationshipTypes/importer/relationshipTypesImporterSelectors';
import RelationshipTypesService from 'src/modules/relationshipTypes/relationshipTypesService';
import fields from 'src/modules/relationshipTypes/importer/relationshipTypesImporterFields';
import { i18n } from 'src/i18n';

const relationshipTypesImporterActions = importerActions(
  'RELATIONSHIPTYPES_IMPORTER',
  selectors,
  RelationshipTypesService.import,
  fields,
  i18n('entities.relationshipTypes.importer.fileName'),
);

export default relationshipTypesImporterActions;