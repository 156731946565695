import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/readings/importer/readingsImporterSelectors';
import ReadingsService from 'src/modules/readings/readingsService';
import fields from 'src/modules/readings/importer/readingsImporterFields';
import { i18n } from 'src/i18n';

const readingsImporterActions = importerActions(
  'READINGS_IMPORTER',
  selectors,
  ReadingsService.import,
  fields,
  i18n('entities.readings.importer.fileName'),
);

export default readingsImporterActions;