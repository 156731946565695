import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.documents.fields.id'),
  },
  {
    name: 'documentName',
    label: i18n('entities.documents.fields.documentName'),
  },
  {
    name: 'documentType',
    label: i18n('entities.documents.fields.documentType'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'documentUpload',
    label: i18n('entities.documents.fields.documentUpload'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'documentOfCongregant',
    label: i18n('entities.documents.fields.documentOfCongregant'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'documentOfGroup',
    label: i18n('entities.documents.fields.documentOfGroup'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'documentOfProject',
    label: i18n('entities.documents.fields.documentOfProject'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'documentOfEvent',
    label: i18n('entities.documents.fields.documentOfEvent'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'documentOfSermon',
    label: i18n('entities.documents.fields.documentOfSermon'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.documents.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.documents.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
