import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/services/importer/servicesImporterSelectors';
import ServicesService from 'src/modules/services/servicesService';
import fields from 'src/modules/services/importer/servicesImporterFields';
import { i18n } from 'src/i18n';

const servicesImporterActions = importerActions(
  'SERVICES_IMPORTER',
  selectors,
  ServicesService.import,
  fields,
  i18n('entities.services.importer.fileName'),
);

export default servicesImporterActions;