import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.readings.fields.id'),
  },
  {
    name: 'readingTitle',
    label: i18n('entities.readings.fields.readingTitle'),
  },
  {
    name: 'readingBook',
    label: i18n('entities.readings.fields.readingBook'),
  },
  {
    name: 'readingChapter',
    label: i18n('entities.readings.fields.readingChapter'),
  },
  {
    name: 'readingStartVerse',
    label: i18n('entities.readings.fields.readingStartVerse'),
  },
  {
    name: 'readingEndVerse',
    label: i18n('entities.readings.fields.readingEndVerse'),
  },
  {
    name: 'readingSermons',
    label: i18n('entities.readings.fields.readingSermons'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.readings.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.readings.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
