import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'membershipTypeName',
    label: i18n('entities.membershipType.fields.membershipTypeName'),
    schema: schemas.string(
      i18n('entities.membershipType.fields.membershipTypeName'),
      {
        "required": true,
        "min": 2,
        "max": 255
      },
    ),
  },
  {
    name: 'membershipTypeDescription',
    label: i18n('entities.membershipType.fields.membershipTypeDescription'),
    schema: schemas.string(
      i18n('entities.membershipType.fields.membershipTypeDescription'),
      {
        "max": 21845
      },
    ),
  },
  {
    name: 'membershipTypeCongregants',
    label: i18n('entities.membershipType.fields.membershipTypeCongregants'),
    schema: schemas.relationToMany(
      i18n('entities.membershipType.fields.membershipTypeCongregants'),
      {},
    ),
  },
];