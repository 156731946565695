import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.contributions.fields.id'),
  },
  {
    name: 'contributionID',
    label: i18n('entities.contributions.fields.contributionID'),
  },
  {
    name: 'contributionType',
    label: i18n('entities.contributions.fields.contributionType'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'contributionOfCongregant',
    label: i18n('entities.contributions.fields.contributionOfCongregant'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'contributionOfGroup',
    label: i18n('entities.contributions.fields.contributionOfGroup'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'contributionTowardsProject',
    label: i18n('entities.contributions.fields.contributionTowardsProject'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'contributionTowardsEvent',
    label: i18n('entities.contributions.fields.contributionTowardsEvent'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'contributionService',
    label: i18n('entities.contributions.fields.contributionService'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.contributions.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.contributions.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
