import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.services.fields.id'),
  },
  {
    name: 'serviceName',
    label: i18n('entities.services.fields.serviceName'),
  },
  {
    name: 'serviceDate',
    label: i18n('entities.services.fields.serviceDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'serviceAnnouncement',
    label: i18n('entities.services.fields.serviceAnnouncement'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'serviceSermon',
    label: i18n('entities.services.fields.serviceSermon'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'serviceContributions',
    label: i18n('entities.services.fields.serviceContributions'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'serviceLeadingGroup',
    label: i18n('entities.services.fields.serviceLeadingGroup'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.services.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.services.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
