import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.responsorialPsalms.fields.id'),
  },
  {
    name: 'responsorialPsalmsText',
    label: i18n('entities.responsorialPsalms.fields.responsorialPsalmsText'),
  },
  {
    name: 'responsorialPsalmsFromReading',
    label: i18n('entities.responsorialPsalms.fields.responsorialPsalmsFromReading'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.responsorialPsalms.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.responsorialPsalms.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
