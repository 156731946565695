import list from 'src/modules/events/list/eventsListReducers';
import form from 'src/modules/events/form/eventsFormReducers';
import view from 'src/modules/events/view/eventsViewReducers';
import destroy from 'src/modules/events/destroy/eventsDestroyReducers';
import importerReducer from 'src/modules/events/importer/eventsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
